import { PlusIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { Badge } from '@urbansportsclub/components/Badge';
import { Button } from '@urbansportsclub/components/Button';
import { AxiosResponse } from 'axios';
import Dialog from 'components/atoms/Tailwind/Diaglog/Dialog';
import Input from 'components/atoms/Tailwind/Input';
import { CompanyContext } from 'contexts/CompanyContext';
import { UserContext } from 'contexts/UserContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import API from 'utils/api';
import { TrackOnInviteUserButtonClickCancelled, TrackOnInviteUserButtonClickError, TrackOnInviteUserButtonClickStart, TrackOnInviteUserButtonClickSuccess, TrackOnRemoveUserButtonClickCancelled, TrackOnRemoveUserButtonClickError, TrackOnRemoveUserButtonClickStart, TrackOnRemoveUserButtonClickSucceeded } from './ManageTeamTracking';
import { useAuthorize } from 'services/authorizeService';
import { useLocation } from 'react-router-dom';

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

interface GetUser {
  corporateUsers: string[];
}

export function ManageTeam() {
  const [buttonAction, setButtonAction] = useState<
  'add' | 'remove' | undefined
  >();
  const [disabled, setDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState<string | undefined>();
  const [error, setError] = useState<boolean>(false);
  const { company } = useContext(CompanyContext);
  const [users, setUsers] = useState<GetUser | undefined>();
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { canRemoveUser, canAddUser } = useAuthorize();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location?.hash) {
      const element = document.getElementById(location.hash.slice(1));
      element && element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const getUsers = useCallback(async () => {
    try {
      const response: AxiosResponse<GetUser> = await API.get(
        `/companies/${company?.id || ''}/users`,
      );
      setUsers(response.data);
      setLoading(false);
    } catch (e) {
      setError(true);
    }
  }, [company]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  function addUser() {
    setButtonAction('add');
    setIsModalOpen(true);
    setDisabled(true);
    TrackOnInviteUserButtonClickStart();
  }

  function removeUser(emailToRemove: string) {
    setEmail(emailToRemove);
    setButtonAction('remove');
    setIsModalOpen(true);
    setDisabled(true);
    setLoading(false);
    TrackOnRemoveUserButtonClickStart();
  }

  function onModalClose() {
    setIsModalOpen(false);
    setDisabled(false);
    setEmail(undefined);
    if (buttonAction === 'add') {
      TrackOnInviteUserButtonClickCancelled();
    } else {
      TrackOnRemoveUserButtonClickCancelled();
    }
  }

  function onModalConfirm() {
    if (buttonAction === 'add') {
      onAddUser();
    } else {
      onRemoveUser();
    }
  }

  async function onAddUser() {
    try {
      setLoading(true);
      await API.put(`/companies/${company?.id || ''}/users`, {
        email,
      });
      setIsModalOpen(false);
      setLoading(false);
      getUsers();
      setDisabled(false);
      toast(
        t('manage_team.toast.add_member').replace('{{email}}', email || ''),
      );
      TrackOnInviteUserButtonClickSuccess();
    } catch (e) {
      setError(true);
      TrackOnInviteUserButtonClickError();
    }
  }

  async function onRemoveUser() {
    try {
      setLoading(true);
      await API.put(`/companies/${company?.id || ''}/users/remove`, {
        email,
      });
      setIsModalOpen(false);
      setLoading(false);
      getUsers();
      setDisabled(false);
      toast(
        t('manage_team.toast.remove_member').replace('{{email}}', email || ''),
      );
      TrackOnRemoveUserButtonClickSucceeded();
    } catch (e) {
      setError(true);
      TrackOnRemoveUserButtonClickError();
    }
  }

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    !emailRegex.test(e.target.value) ? setError(true) : setError(false);
    setEmail(e.target.value);
  }

  function getDialogTitle() {
    if (buttonAction === 'add') {
      return t('manage_team.add_member.dialog.title');
    }
    return t('manage_team.remove_member.dialog.title');
  }

  function getDialogDescription() {
    if (buttonAction === 'add') {
      return t('manage_team.add_member.dialog.subheading');
    }
    return t('manage_team.remove_member.dialog.subheading').replace(
      '{{email}}',
      email || '',
    );
  }

  function filterUsers(corporateUser: string) {
    return corporateUser !== user?.username 
    && corporateUser.indexOf('one.fit') === -1 
    && corporateUser.indexOf('urbansportsclub.com') === -1;
  }

  return (
    <>
      <Dialog
        title={getDialogTitle()}
        description={getDialogDescription()}
        open={isModalOpen}
        onCancel={onModalClose}
        onConfirm={onModalConfirm}
        variants={buttonAction === 'add' ? 'primary' : 'danger'}
        onConfirmText={
          buttonAction === 'add'
            ? t('manage_team.add_membe.dialog.button')
            : t('manage_team.remove_member.dialog.button')
        }
        onCancelText={t('manage_team.dialog.cancel_button')}
        disabled={error}
        loading={loading}
      >
        {buttonAction === 'add' && (
          <div className="mt-4">
            <label
              htmlFor="email"
              className="block text-base text-black-midnight-light-800 font-bold leading-6"
            >
              Email Address
            </label>

            <div className="relative mt-2 rounded-md shadow-sm">
              <Input
                type="email"
                onChange={onInputChange}
                icon={<EnvelopeIcon className="h-5 w-5 text-gray-400" />}
                placeholder={t('manage_team.add_member_placeholder')}
              />
            </div>
          </div>
        )}
      </Dialog>
      <section className="info-section" id="manage_team">
        <div className="flex space-between flex-col sm:flex-row">
          <div className="flex flex-col space-y-1 mb-4">
            <div className="flex space-x-2">
              <h2 className="capitalize heading-5">{t('manage_team.title')}</h2>
              <Badge>{t('badge.new_label')}</Badge>
            </div>
            <div>
              <span className="text-base text-normal">
                {t('manage_team.subheading')}
              </span>
            </div>
          </div>
          {canAddUser() && <Button
            small
            className="mr-auto sm:ml-auto sm:mt-4 sm:mr-0"
            disabled={buttonAction === 'add' && disabled}
            onClick={addUser}
          >
            <PlusIcon className="w-4 h-4 text-white-natural-50" />
            {t('manage_team.add_member.invite_button')}
          </Button>}
        </div>
        <div className="info-section__row mt-4">
          <div className="overflow-hidden rounded-md border border-grey-dark-200 bg-white">
            <ul className="divide-y divide-grey-dark-200">
              {users &&
                users.corporateUsers
                  .filter(filterUsers)
                  .map((corporateUser) => (
                    <li key={corporateUser} className="px-6 py-5">
                      <div className="flex justify-between items-baseline flex-col sm:flex-row">
                        <div className="flex">
                          <div className="flex flex-col-reverse sm:space-y-0 sm:space-x-2 sm:flex-row">
                            <span className="text-base text-black-midnight-light-800 font-normal">
                              {corporateUser}
                            </span>
                            {false && (
                              <Badge
                                variant="secondary"
                                className="inline-flex gap-x-2 mb-2 sm:mb-0"
                              >
                                {t('badge.pending_label')}
                                <svg
                                  viewBox="0 0 6 6"
                                  aria-hidden="true"
                                  className="h-1.5 w-1.5 fill-yellow-500"
                                >
                                  <circle r={3} cx={3} cy={3} />
                                </svg>
                              </Badge>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-x-6 mt-2 items-baseline sm:mt-0 sm:ml-auto">
                         {canRemoveUser() && <>
                            <Button
                              variant="danger-secondary"
                              small
                              disabled={
                                corporateUser === email &&
                                disabled &&
                                buttonAction === 'remove'
                              }
                              onClick={() => {
                                removeUser(corporateUser);
                              }}
                            >
                              {t('manage_team.remove_member.dialog.button')}
                            </Button>
                          </>}
                        </div>
                      </div>
                    </li>
                  ))}
              {users &&
                users.corporateUsers
                  .filter((corporateUser) => corporateUser === user?.username)
                  .map((corporateUser) => (
                    <li key={corporateUser} className="px-6 py-6">
                      <div className="flex justify-between items-baseline flex-col sm:flex-row">
                        <div className="flex">
                          <div className="flex flex-col-reverse sm:space-y-0 sm:space-x-2 sm:flex-row">
                            <span className="text-base text-black-midnight-light-800 font-normal">
                              {corporateUser}
                            </span>
                            {false && (
                              <Badge
                                variant="secondary"
                                className="inline-flex gap-x-2 mb-2 sm:mb-0"
                              >
                                {t('badge.pending_label')}
                                <svg
                                  viewBox="0 0 6 6"
                                  aria-hidden="true"
                                  className="h-1.5 w-1.5 fill-yellow-500"
                                >
                                  <circle r={3} cx={3} cy={3} />
                                </svg>
                              </Badge>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-x-6 mt-2 items-baseline sm:mt-0 sm:ml-auto">
                          <span className="text-sm text-[#2E374261] font-normal">
                            {t('manage_team.cannot_remove')}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
