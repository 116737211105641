import { createContext, useCallback, useEffect, useState } from 'react';
import { isAuthenticated } from 'services/authService';
import jwtDecode from 'jwt-decode';
import { getFromLocalStorage } from 'utils/localStorage';

interface User {
  id: string;
  role: string;
  language: string;
  username: string;
  exp: number;
  iat: number;
}

export enum UserRole {
  Admin = 'ROLE_ADMIN',
  Finance = 'ROLE_FINANCE',
  HR = 'ROLE_HR',
  SuperAdmin = 'ROLE_SUPER_ADMIN',
  UNDEFINED = 'ROLE_UNDEFINED',
}

export interface IUserContext {
  user: User | undefined;
  setUser: () => void;
}

export const ADMIN_ROLES = [UserRole.Admin.toString(), UserRole.SuperAdmin.toString()];


export const UserContext = createContext<IUserContext>({
  user: {} as User,
  setUser: () => null,
});

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User>({} as User);

  const loadUser = useCallback(() => {
    if (isAuthenticated()) {
      setUser(jwtDecode<User>(getFromLocalStorage(
        process.env.REACT_APP_AUTH_TOKEN as string,
      ) as string));
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  
    
  return <UserContext.Provider value={{ user, setUser: loadUser }}>{children}</UserContext.Provider>;
}